import React from "react";
import styled from "styled-components";
import "../styles/global.scss";
import Navigation from "./Navigation";
import Observer from "./Observer";
import Footer from "./Footer";

const Content = styled.div`
    
`

const Layout = ({landing, children, settings, location, lang, animated, metadata, privacy, imprint, placeholder, servicesheadline, photoheadline, aboutheadline, videoheadline}) => {

    return (
        <>
            <Observer></Observer>
            <Content>
                {children}
            </Content>
            {placeholder ? <></> :
                <Footer location={location} settings={settings} metadata={metadata} privacy={privacy} imprint={imprint} servicesheadline={servicesheadline} photoheadline={photoheadline} aboutheadline={aboutheadline} videoheadline={videoheadline}></Footer>
            }
        </>
    )
}


export default Layout
