import React from 'react';
import Icon from "./Icon";
import {Link} from "gatsby";
import {localeLink} from "../util";

const Footer = ({settings, privacy, imprint, metadata, location, photoheadline, servicesheadline, aboutheadline, videoheadline}) => {

    const today = new Date();

    return (
        <div className={"footer__outer"}>
            <div className="footer__inner">
                <div className="footer__top bg-light">
                    <div className="left">
                        <div className="top">
                            <svg className="svglogo" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 viewBox="0 0 169.5 101">
                                <g id="Ebene_1">
                                    <g>
                                        <g>
                                            <path className="svg-fill-right" d="M21.2,21.1h9.5v60.3h-9.5V21.1z"/>
                                            <path className="svg-fill-right" d="M42.1,72.4c1,0.1,3.1,0.4,4.7,0.4c3,0,5.4-1.1,5.4-5.8V21h9.5v45.3c0,12.1-6.5,15.1-13.6,15.1
				c-2.1,0-4.5-0.3-6-0.5V72.4z"/>
                                            <path className="svg-fill-right" d="M76,35.5c0-9.6,5.1-15.1,14.4-15.1c9.3,0,14.4,5.5,14.4,15.1v31.3c0,9.6-5.1,15.1-14.4,15.1
				c-9.3,0-14.4-5.5-14.4-15.1V35.5z M85.5,67.5c0,4.3,1.9,5.9,4.9,5.9s4.9-1.6,4.9-5.9V34.9c0-4.3-1.9-5.9-4.9-5.9
				s-4.9,1.6-4.9,5.9V67.5z"/>
                                            <path className="svg-fill-right" d="M147.8,34.9v2.2c0,6.2-1.9,10.1-6.1,12.1v0.2c5.1,2,7.1,6.5,7.1,12.8V67c0,9.3-4.9,14.3-14.4,14.3h-14.9
				V21.1h14.3C143.6,21.1,147.8,25.6,147.8,34.9z M128.9,29.7v15.9h3.7c3.5,0,5.7-1.5,5.7-6.4v-3.4c0-4.3-1.5-6.2-4.8-6.2h-4.6V29.7
				z M128.9,54.2v18.5h5.4c3.2,0,4.9-1.5,4.9-5.9v-5.3c0-5.6-1.8-7.3-6.1-7.3C133.1,54.2,128.9,54.2,128.9,54.2z"/>
                                        </g>
                                        <path className="svg-fill-right" d="M42.1,1.5V11H160v80.5H9.5V1.7H0V101h169.5V1.5H42.1z"/>
                                        <g>
                                            <circle className="svg-fill-red" cx="25.9" cy="6.2" r="6.2"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p>{settings.address_street}, {settings.address_nr}</p>
                            <p>{settings.address_plz}, {settings.address_ort}</p>
                            <p>E-Mail: <a href={"mailto:" + settings.email} className={"hover-moveup inlineblock"}>{settings.email}</a></p>
                                <p>Phone: <a href={"tel:" + settings.telefon} className={"hover-moveup inlineblock"}>{settings.telefon}</a></p>
                            <div className="socialmediacontainer">
                                {settings?.sociallinks?.map((link, index) => {
                                    return <a href={link?.value} target={"_blank"} key={index} className="iconbg bg-dark hover-moveup">
                                        <Icon icon={link?.type} color="ffffff"></Icon>
                                    </a>
                                    })}
                            </div>
                        </div>
                        <div className="bottom bg-dark color-light desktop">
                            <div className="left">
                                © {today.getFullYear()} Ijob Brandstätter. All Rights Reserved.
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="top">
                            <div className="first">
                                <h6>WORK</h6>
                                <Link to={localeLink("/#videography", location)} className={"footerlink hover-moveup"}>{videoheadline}</Link>
                                <Link to={localeLink("/#photography", location)} className={"footerlink hover-moveup"}>{photoheadline}</Link>
                            </div>
                            <div className="second">
                                <h6>ABOUT</h6>
                                <Link to={localeLink("/#about", location)} className={"footerlink hover-moveup"}>{aboutheadline}</Link>
                                <Link to={localeLink("/contact", location)} className={"footerlink hover-moveup"}>{metadata.contact}</Link>
                                <Link to={localeLink("/#services", location)} className={"footerlink hover-moveup"}>{servicesheadline}</Link>
                            </div>
                        </div>

                        <div className="bottom bg-dark color-light">
                            <div className="first">
                                <Link to={localeLink("/imprint", location)} className={"color-light hover-moveup inlineblock"}>{imprint}</Link>
                            </div>
                            <div className="second">
                                <Link to={localeLink("/privacy", location)} className={"color-light hover-moveup inlineblock"}>{privacy}</Link>
                            </div>
                        </div>
                        <div className="footer__ijobcredits mobile">
                            © {today.getFullYear()} Ijob Brandstätter. All Rights Reserved.
                        </div>
                    </div>
                </div>
                <div className="footer__bottom bg-dark"></div>
                <div className="footer__inbetween bg-dark color-light">
                    <div>
                        <div className="first">
                            <Link to={localeLink("/imprint", location)} className={"color-light hover-moveup inlineblock"}>{imprint}</Link>
                        </div>
                        <div className="second">
                            <Link to={localeLink("/privacy", location)} className={"color-light hover-moveup inlineblock"}>{privacy}</Link>
                        </div>
                    </div>
                    <div className="footercredits">
                        © {today.getFullYear()} Ijob Brandstätter. All Rights Reserved.
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer;
