import React from "react";
import { BsPlay, BsInstagram, BsFacebook, BsCamera } from 'react-icons/bs'
import { AiOutlineInstagram, AiOutlineCamera, AiOutlineYoutube } from 'react-icons/ai'
import { ImFilm } from 'react-icons/im'
import {FiLinkedin} from 'react-icons/fi'

const Icon = ({icon, color}) => {
    switch(icon){
        case 'play':
            return <BsPlay className={"play"} color={color}/>;
        case 'camera':
            return <AiOutlineCamera color={color}/>;
        case 'facebook':
            return <BsFacebook color={color}/>;
        case 'instagram':
            return <AiOutlineInstagram color={color}/>;
        case 'film':
            return <ImFilm color={color}/>;
        case 'youtube':
            return <AiOutlineYoutube color={color}/>;
        case 'linkedin':
            return <FiLinkedin color={color} className={"customStroke"}/>;
    }
    return (
        <></>
    )
}

export default Icon;
